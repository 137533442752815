/* GENERAL */
* {
    font-family: 'Poppins', sans-serif;
}

html, body, #root, #site, #site>* {
    font-size: 16px;
}

html, body, #root, #site {
    height: 100%;
}

a {
    color: #0b6fba !important;
    text-decoration: none;
}

/*NAVBAR*/
.bg-dark{
    background-color: #0b6fba!important;
}

.navbar-light .navbar-brand{
    color: white!important;
}

.navbar-light .navbar-toggler {
    color:white!important;
    border-color:white!important;
}

.navbar-light .navbar-nav .nav-link {
    color: white!important;
}

.nav-link{
    font-size: 1.25em!important;
}

.dropdown-item{
    font-size: 1em!important;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
}

.alert-danger{
    font-size: 1.2em;
}

/* TABLE */

th[class*='thead-center'] {
    text-align: center !important;
}

th, td{
    vertical-align: middle!important;
}

/* SPECIFIC COLORS */

.bg-grey {
    background-color: #bdbdbd !important;
}

.bg-green {
    background-color: #52c41a !important;
}

.bg-orange {
    background-color: #faad14 !important;
}

.bg-red {
    background-color: #ff4d4f !important;
}

.bg-blue {
    background-color: #2399f3 !important;
}

.clr-primary {
    color: #0b6fba;
}

.red-txt {
    color: red;
}

/* SPECIFIC DECORATION */

.bld-txt {
    font-weight: bold;
}

/* SPECIFIC ROUND */

.rad-50 {
    border-radius: 50%;
}