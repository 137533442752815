.collapseButton {
    width: 34px !important;
    height: 34px !important;
    box-shadow: none;
}

.svgIcon {
    transition: transform 0.3s ease-out;
}

.tabsHeight > div[class="ant-tabs-content-holder"] > div[class="ant-tabs-content ant-tabs-content-top"] {
    height: calc(100% - 5px) !important;
}

.tabsHeight > div[class="ant-tabs-content-holder"] > div[class="ant-tabs-content ant-tabs-content-top"] > div[class="ant-tabs-tabpane ant-tabs-tabpane-active"] {
    height: 100% !important;
}

/* Button */

.btstrapPrimaryBtn {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    background-color: #0b6fba;
}

.btstrapPrimaryBtn:hover {
    background-color: #0b6fba;
}