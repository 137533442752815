/* _Actions */

.actionsTitlePanel {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: auto;
    color: rgba(0, 0, 0, 0.88);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

/* _Contacts */

.contactsTabs > div[class="ant-tabs-content-holder"] > div[class="ant-tabs-content ant-tabs-content-top"] {
    height: 100% !important;
}

.contactsTabs > div[class="ant-tabs-content-holder"] > div[class="ant-tabs-content ant-tabs-content-top"] > div[class="ant-tabs-tabpane ant-tabs-tabpane-active"] {
    height: 100% !important;
}

/* _Invoices */

.tableHeight {
    height: 100%;
}

.tableHeight > div[class*="ant-spin-nested-loading"] {
    height: 100%;
}

.tableHeight > div[class*="ant-spin-nested-loading"] > div[class="ant-spin-container"] {
    height: 100%;
}

.tableHeight > div[class*="ant-spin-nested-loading"] > div[class="ant-spin-container"] > div[class*="ant-table"] {
    height: 100%;
}

.tableHeight > div[class*="ant-spin-nested-loading"] > div[class="ant-spin-container"] > div[class*="ant-table"] > div[class="ant-table-container"] {
    height: 100%;
}

.tableHeight > div[class*="ant-spin-nested-loading"] > div[class="ant-spin-container"] > div[class*="ant-table"] > div[class="ant-table-container"] > div[class="ant-table-body"] {
    height: 100% !important;
    max-height: calc(100% - 30px) !important;
    overflow-y: auto !important;
}