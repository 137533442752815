.bubbleBox {
    width: 280px;
    margin-top: 8px;
    border-radius: 15px;
    color: #fff;
    padding: 15px;
    font-weight: 400;
    position: relative;
}
    
.bubbleRight:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid #0b6fba;
    border-right: 15px solid transparent;
    border-top: 15px solid #0b6fba;
    border-bottom: 15px solid transparent;
    right: -16px;
    top: 0px;
}
  
    
.bubbleLeft:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 15px solid transparent;
    border-right: 15px solid #9c9a97;
    border-top: 15px solid #9c9a97;
    border-bottom: 15px solid transparent;
    left: -16px;
    top: 0px;
}