.rowTextarea {
    border: 1px solid #d9d9d9 !important;
    border-radius: 6px !important;
    height: 100% !important;
    width: 100%;
}

.rowTextarea > div[class*="ant-col"] {
    height: 100%;
}

.textarea {
    margin: 0;
    padding: 4px 4px 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    line-height: 1.57;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100% !important;
    background-color: #ffffff;
    background-image: none;
    border: none;
    border-radius: 6px;
    transition: all 0.2s;
    resize: none;
}

.textarea:focus {
    outline: none;
}

.textarea::-webkit-scrollbar {
    width: 0.35em;
}

.textarea::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 5px;
}