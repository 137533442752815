.ag-header-cell-label, .ag-header-group-cell-label {
    justify-content: center;
    font-size: 14px !important;
}

.dot-8 > span {
    height: 8px !important;
    width: 8px !important;
}

.dot-10 > span {
    height: 10px !important;
    width: 10px !important;
}

/* RANGEPICKER */

.ant-picker-input > input {
    text-align: center; 
}

.ant-picker-input > input::placeholder {
    text-align: center; 
}