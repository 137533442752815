/* ~~~~~~~ INIT. BTN ~~~~~~~ */

.customBtn {		
	position: relative;	
	padding: 1.4rem 4.2rem;
	padding-right: 3.1rem;
	color: var(--inv);
	letter-spacing: 1.1rem;
	text-transform: uppercase;
	transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);	
	cursor: pointer;
	user-select: none;
}

.customBtn:before, .customBtn:after {
	content: '';
	position: absolute;	
	transition: inherit;
	z-index: -1;
}

.customBtn:hover {
	color: var(--def);
	transition-delay: .5s;
}

.customBtn:hover:before {
	transition-delay: 0s;
}

.customBtn:hover:after {
	background: var(--inv);
	transition-delay: .35s;
}

.btnWithLink:hover > a {
    color: #fff !important;
    transition-delay: .5s;
}

/* From Top */

.fromTop:before, 
.fromTop:after {
	left: 0;
	height: 0;
	width: 100%;
}

.fromTop:before {
	bottom: 0;	
	border: 1px solid var(--inv);
	border-top: 0;
	border-bottom: 0;
}

.fromTop:after {
	top: 0;
	height: 0;
}

.fromTop:hover:before,
.fromTop:hover:after {
	height: 100%;
}

/* From Left */

.fromLeft:before, 
.fromLeft:after {
	top: 0;
	width: 0;
	height: 100%;
}

.fromLeft:before {
	right: 0;
	border: 1px solid var(--inv);
	border-left: 0;
	border-right: 0;	
}

.fromLeft:after {
	left: 0;
}

.fromLeft:hover:before,
.fromLeft:hover:after {
	width: 100%;
}

/* From Right */

.fromRight:before, 
.fromRight:after {
	top: 0;
	width: 0;
	height: 100%;
}

.fromRight:before {
	left: 0;
	border: 1px solid var(--inv);
	border-left: 0;
	border-right: 0;	
}

.fromRight:after {
	right: 0;
}

.fromRight:hover:before,
.fromRight:hover:after {
	width: 100%;
}

/* From center */

.fromCenter:before {
	top: 0;
	left: 50%;
	height: 100%;
	width: 0;
	border: 1px solid var(--inv);
	border-left: 0;
	border-right: 0;
}

.fromCenter:after {
	bottom: 0;
	left: 0;
	height: 0;
	width: 100%;
	background: var(--inv);
}

.fromCenter:hover:before {
	left: 0;
	width: 100%;
}

.fromCenter:hover:after {
	top: 0;
	height: 100%;
}

/* From Bottom */

.fromBottom:before, 
.fromBottom:after {
	left: 0;
	height: 0;
	width: 100%;
}

.fromBottom:before {
	top: 0;	
	border: 1px solid var(--inv);
	border-top: 0;
	border-bottom: 0;
}

.fromBottom:after {
	bottom: 0;
	height: 0;
}

.fromBottom:hover:before,
.fromBottom:hover:after {
	height: 100%;
}


/* ~~~~~~~~~~~~ GLOBAL SETTINGS ~~~~~~~~~~~~ */

*, *:before, *:after {
	/* box-sizing: border-box; */
}

body {
	--def: #fff; 	
	--inv: #0b6fba;
	/* display: flex; */
	/* justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	width: 100%; */
	/* background-image: linear-gradient(-25deg, #616161 0%, #96B7C4 100%); */
}

/* html {
	font-size: 12px;
	font-family: 'Playfair Display', serif;
}

div {margin-bottom: 3rem;}
div:last-child {margin-bottom: 0;} */