.modalContent {
    height: calc(100% - 200px);
    padding-bottom: 0;
}

.modalContent > div[class="ant-modal-content"] {
    padding: 10px 20px;
    height: 100%;
}

.modalContent > div[class="ant-modal-content"] > div[class="ant-modal-body"] {
    height: 100%;
}